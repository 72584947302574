import AppWidget_ResponsiveComponent from '@wix/thunderbolt-elements/src/components/AppWidget/viewer/skinComps/Responsive/Responsive.skin';


const AppWidget_Responsive = {
  component: AppWidget_ResponsiveComponent
};


export const components = {
  ['AppWidget_Responsive']: AppWidget_Responsive
};

